<template>
  <div class="song-list">
    <Songs class="song"
           v-for="(song,index) in songs"
           :key="index"
           :song="song"
           :albumId="albumId">
    </Songs>
    <p class="medium no-song pt-4 pb-4"
       v-if="totalSongs == 0">
      No Song
    </p>
  </div>
</template>
<script>
  const Songs = () => import('./Song.vue');
  export default {
    name  : 'SongList',
    props : {
      songs   : Array,
      albumId : Number,
    },
    data() {
      return {
        totalSongs : null,
      }
    },
    components : {
      Songs,
    },
    watch : {
      songs(val) {
        this.totalSongs = val.length;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/mobile_music/song-list";
</style>